/* DevTools styles for development mode only */

/* Remove default outline for component wrappers */
.dev-component-wrapper {
  all: unset;
  display: contents;
}

/* Show outline on detected components */
body.dev-tools-active [data-component-name] {
  outline: 2px solid rgba(65, 184, 131, 0.5) !important; /* Vue green */
  outline-offset: 2px !important;
  position: relative;
}

/* Show outline on all elements when in dev tools mode */
body.dev-tools-active *:not([data-component-name]) {
  outline: 1px solid rgba(0, 120, 215, 0.15) !important; /* Light blue */
  outline-offset: 1px !important;
}

/* Enhanced outline on hover for components */
body.dev-tools-active [data-component-name]:hover {
  outline: 2px dashed rgba(65, 184, 131, 0.8) !important;
  outline-offset: 2px !important;
  cursor: pointer !important;
  position: relative;
}

/* Enhanced outline on hover for all other elements */
body.dev-tools-active *:not([data-component-name]):hover {
  outline: 2px dashed rgba(0, 120, 215, 0.5) !important;
  outline-offset: 2px !important;
  cursor: pointer !important;
  position: relative;
}

/* Add a tooltip for components */
body.dev-tools-active [data-component-name]:hover::after {
  content: attr(data-component-name);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  background: rgba(65, 184, 131, 0.9);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 10000;
  pointer-events: none;
}

/* Add a tooltip for other elements */
body.dev-tools-active *:not([data-component-name]):hover::after {
  content: attr(class);
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  background: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 9999;
  pointer-events: none;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
}