@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink {
  50% { opacity: 0 }
}

.citation-tooltip {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease, visibility 0.2s ease;
}

.citation-tooltip.visible {
  opacity: 1;
  visibility: visible;
}

.typing-animation {
  overflow: hidden;
  white-space: pre-wrap;
  animation: 
    typing 1.5s steps(50, end);
}

.cursor::after {
  content: '|';
  animation: blink 1s step-end infinite;
}

.particle {
  position: absolute;
  pointer-events: none;
  mix-blend-mode: screen;
}

@keyframes float {
  0% { transform: translateY(0px) rotate(0deg); }
  50% { transform: translateY(-20px) rotate(180deg); }
  100% { transform: translateY(0px) rotate(360deg); }
}

.floating {
  animation: float 3s ease-in-out infinite;
}

.sidebar::-webkit-scrollbar {
  width: 12px;
}

.sidebar::-webkit-scrollbar-track {
  background: #F9FAFB;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #D1D5DB;
  border-radius: 6px;
  border: 3px solid #F9FAFB;
}

/* For Firefox */
.sidebar {
  scrollbar-color: #D1D5DB #F9FAFB;
  scrollbar-width: thin;
}
